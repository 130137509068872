/* eslint no-param-reassign: 0 */
import { createSlice } from '@reduxjs/toolkit'
import { api } from '@/redux/apiSlice'
import TAGS from '@/redux/cacheTags'
import { objectToQueryParams } from '@/utils/url-utils'

const extendApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllBundles: build.query({
            query: ({ page, page_size, ...params }) => ({
                url: `bundles/`,
                params: objectToQueryParams({
                    page: page || 1,
                    page_size: page_size || 10,
                    ...params,
                }),
            }),
            providesTags: [TAGS.bundles],
        }),
        getBundle: build.query({
            query: ({ id }) => `bundles/${id}/`,
            providesTags: [TAGS.bundle],
        }),
        createBundle: build.mutation({
            query: (data) => ({
                url: `bundles/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.bundles],
        }),
        updateBundle: build.mutation({
            query: ({ id, ...data }) => ({
                url: `bundles/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.bundle, TAGS.bundles],
        }),
        deleteBundle: build.mutation({
            query: ({ id }) => ({
                url: `bundles/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.bundle, TAGS.bundles],
        }),
        duplicateBundle: build.mutation({
            query: ({ id }) => ({
                url: `bundles/${id}/duplicate/`,
                method: 'POST',
            }),
            invalidatesTags: [TAGS.bundle, TAGS.bundles],
        }),

        // Prices
        getAllBundlePrices: build.query({
            query: ({ bundle_id }) => `bundles/${bundle_id}/prices/`,
            providesTags: [TAGS.bundlePrices],
        }),
        createBundlePrice: build.mutation({
            query: ({ bundle_id, price }) => ({
                url: `bundles/${bundle_id}/prices/`,
                method: 'POST',
                body: price,
            }),
            invalidatesTags: [TAGS.bundle, TAGS.bundles, TAGS.bundlePrices],
        }),
        updateBundlePrice: build.mutation({
            query: ({ bundle_id, price_id, ...data }) => ({
                url: `bundles/${bundle_id}/prices/${price_id}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [TAGS.bundle, TAGS.bundles, TAGS.bundlePrices],
        }),
        deleteBundlePrice: build.mutation({
            query: ({ bundle_id, price_id }) => ({
                url: `bundles/${bundle_id}/prices/${price_id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.bundle, TAGS.bundles, TAGS.bundlePrices],
        }),

        // Products
        getAllBundleProducts: build.query({
            query: ({ id }) => ({
                url: `bundles/${id}/products/`,
                params: {
                    page_size: 999,
                },
            }),
            providesTags: [TAGS.bundleProducts],
        }),
        createBundleProduct: build.mutation({
            query: ({ bundle_id, ...data }) => ({
                url: `bundles/${bundle_id}/products/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.bundleProducts],
        }),
        updateBundleProduct: build.mutation({
            query: ({ id, bundle_id, ...data }) => ({
                url: `bundles/${bundle_id}/products/${id}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [TAGS.bundleProducts],
        }),
        deleteBundleProduct: build.mutation({
            query: ({ product_id, bundle_id }) => ({
                url: `bundles/${bundle_id}/products/${product_id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.bundleProducts],
        }),

        // media - images
        createBundleMedia: build.mutation({
            query: ({ id: bundle_id, data }) => ({
                url: `bundles/${bundle_id}/media/`,
                method: 'POST',
                body: data,
                format: 'multipart',
            }),
        }),
        deleteBundleMedia: build.mutation({
            query: ({ id: bundleId, mediaId }) => ({
                url: `bundles/${bundleId}/media/${mediaId}/`,
                method: 'DELETE',
            }),
        }),

        // uses
        getBundleUses: build.query({
            query: ({ id }) => ({
                url: `bundles/${id}/uses/`,
            }),
            providesTags: [TAGS.bundleUses],
        }),
        getBulkBundleUses: build.query({
            queryFn: async ({ bundleIds }, _bqa, _n, fetchWithBQ) => {
                const promises = bundleIds.map((id) => fetchWithBQ(`bundles/${id}/uses/`))
                const { data } = await Promise.all(promises).then((results) => ({ data: results }))
                return { data: data?.map((d, i) => ({ ...d.data, bundle_id: bundleIds[i] })) ?? [] }
            },
            providesTags: [TAGS.bulkBundleUses],
        }),
    }),
})

const bundleSlice = createSlice({
    name: 'bundle',
    initialState: {
        editBundleProduct: null,
        editBundlePrice: null,
        editVariantPrice: null,
    },
    reducers: {
        setEditBundlePrice(state, action) {
            state.editBundlePrice = action.payload
        },
        setEditBundleProduct(state, action) {
            state.editBundleProduct = action.payload
        },
        setEditVariantPrice(state, action) {
            state.editVariantPrice = action.payload
        },
    },
})

export const {
    useGetAllBundlesQuery,
    useGetBundleQuery,
    useCreateBundleMutation,
    useUpdateBundleMutation,
    useDeleteBundleMutation,
    useDuplicateBundleMutation,

    useGetAllBundlePricesQuery,
    useCreateBundlePriceMutation,
    useUpdateBundlePriceMutation,
    useDeleteBundlePriceMutation,

    useGetAllBundleProductsQuery,
    useCreateBundleProductMutation,
    useUpdateBundleProductMutation,
    useDeleteBundleProductMutation,

    useCreateBundleMediaMutation,
    useDeleteBundleMediaMutation,

    useGetBundleUsesQuery,
    useGetBulkBundleUsesQuery,
} = extendApi
export const { setEditBundlePrice, setEditBundleProduct, setEditVariantPrice } = bundleSlice.actions
export const { reducer } = bundleSlice
