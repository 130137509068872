import { createSlice } from '@reduxjs/toolkit'
import { api } from '@/redux/apiSlice'
import TAGS from '@/redux/cacheTags'

const extendApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllOffers: build.query({
            query: ({ page, page_size }) => `offers/?page=${page}&page_size=${page_size}`,
            providesTags: [TAGS.offers],
        }),
        getOffer: build.query({
            queryFn: async ({ offer_id }, _bqa, _n, fetchWithBQ) => {
                const { data: offer } = await fetchWithBQ(`offers/${offer_id}/`)
                const target_upsells = []

                const upsells = (offer?.upsells || []).map((upsell) => {
                    const target_upsell_accept = offer?.upsells?.find(
                        (u) => u.target_upsell_accept === upsell?.id,
                    )

                    const target_upsell_decline = offer?.upsells?.find(
                        (u) => u.target_upsell_decline === upsell?.id,
                    )

                    if (target_upsell_accept) {
                        target_upsells.push(target_upsell_accept?.id)
                    }

                    if (target_upsell_decline) {
                        target_upsells.push(target_upsell_decline?.id)
                    }

                    return {
                        ...upsell,
                        target_upsell_accept,
                        target_upsell_decline,
                    }
                })

                return {
                    data: {
                        ...(offer || {}),
                        all_upsells: upsells || [],
                        upsells: upsells?.filter((u) => !target_upsells?.includes(u.id)),
                        target_upsells: upsells?.filter((u) => target_upsells?.includes(u.id)),
                    },
                }
            },
            providesTags: [TAGS.offer],
        }),
        duplicateOffer: build.mutation({
            query: ({ id }) => ({
                url: `offers/${id}/duplicate/`,
                method: 'POST',
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),
        createOffer: build.mutation({
            query: (data) => ({
                url: `offers/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),
        updateOffer: build.mutation({
            query: ({ id, ...data }) => ({
                url: `offers/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),

        // Products
        getAllOfferProducts: build.query({
            query: ({ id }) => `offers/${id}/products/`,
            providesTags: [TAGS.offerProducts],
        }),
        createOfferProduct: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/products/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offerProducts, TAGS.offer],
        }),
        updateOfferProduct: build.mutation({
            query: ({ id, offer_id, ...data }) => ({
                url: `offers/${offer_id}/products/${id}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [TAGS.offerProducts, TAGS.offer],
        }),
        deleteOfferProduct: build.mutation({
            query: ({ id, offer_id }) => ({
                url: `offers/${offer_id}/products/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.offerProducts, TAGS.offer],
        }),

        // Bundles
        getAllOfferBundles: build.query({
            query: ({ id }) => `offers/${id}/offer-bundles/`,
            providesTags: [TAGS.offerBundles],
        }),
        createOfferBundle: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/offer-bundles/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offerBundles, TAGS.offer],
        }),
        deleteOfferBundle: build.mutation({
            query: ({ id, offer_id }) => ({
                url: `offers/${offer_id}/offer-bundles/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.offerBundles, TAGS.offer],
        }),

        // MSRP
        getOfferAllMSRPOverrides: build.query({
            query: ({ offer_id }) => ({
                url: `offers/${offer_id}/msrp-overrides/`,
            }),
            invalidatesTags: [TAGS.offerProducts],
        }),
        createOfferMsrpOverride: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/msrp-overrides/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),
        updateOfferMsrpOverride: build.mutation({
            query: ({ offer_id, overrideId, ...data }) => ({
                url: `offers/${offer_id}/msrp-overrides/${overrideId}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),
        deleteOfferMsrpOverride: build.mutation({
            query: ({ offer_id, override_id }) => ({
                url: `offers/${offer_id}/msrp-overrides/${override_id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),

        // Shipping Rates
        createShippingRate: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/offer-bundles/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),
        updateShippingRate: build.mutation({
            query: ({ offer_id, id, ...data }) => ({
                url: `offers/${offer_id}/offer-bundles/${id}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [TAGS.offers, TAGS.offer],
        }),

        bulkManageVariantShippingRate: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/product-variant-shipping-prices/batch-create/`,
                method: 'POST',
                body: data,
            }),
        }),
        createVariantShippingRate: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/product-variant-shipping-prices/`,
                method: 'POST',
                body: data,
            }),
        }),
        updateVariantShippingRate: build.mutation({
            query: ({ offer_id, id, ...data }) => ({
                url: `offers/${offer_id}/product-variant-shipping-prices/${id}/`,
                method: 'PUT',
                body: data,
            }),
        }),
        deleteVariantShippingRate: build.mutation({
            query: ({ offer_id, id }) => ({
                url: `offers/${offer_id}/product-variant-shipping-prices/${id}/`,
                method: 'DELETE',
            }),
        }),

        // Localized Pricing
        getAllCurrencyOverrides: build.query({
            query: ({ offer_id }) => ({
                url: `offers/${offer_id}/currency-overrides/`,
            }),
            providesTags: [TAGS.offerCurrencyOverrides],
        }),
        createCurrencyOverride: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/currency-overrides/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offerCurrencyOverrides],
        }),
        updateCurrencyOverride: build.mutation({
            query: ({ offer_id, id, ...data }) => ({
                url: `offers/${offer_id}/currency-overrides/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.offerCurrencyOverrides],
        }),

        // Shipping Overrides
        getAllShippingOverrides: build.query({
            query: ({ offer_id }) => ({
                url: `offers/${offer_id}/shipping-discounts/?page=1&page_size=999`,
            }),
            providesTags: [TAGS.offerShippingOverrides],
        }),
        updateShippingOverrides: build.mutation({
            query: ({ offer_id, ...data }) => ({
                url: `offers/${offer_id}/shipping-discounts/bulk-update/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offerShippingOverrides],
        }),

        // OFFER_SUPPORTED_PAYMENTS
        listSupportedPayments: build.query({
            query: ({ offer_id }) => ({
                url: `offers/${offer_id}/supported-payments/?page_size=999`,
            }),
            providesTags: [TAGS.offerPaymentMethods],
        }),
        updateSupportedPayments: build.mutation({
            query: ({ offer_id, id, ...data }) => ({
                url: `offers/${offer_id}/supported-payments/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.offerPaymentMethods],
        }),

        // Offer Groups
        getExistingGroups: build.query({
            query: () => ({
                url: `offergroups/`,
            }),
            providesTags: [TAGS.offerGroups],
        }),
        createOfferGroup: build.mutation({
            query: (data) => ({
                url: `offergroups/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offerGroups],
        }),
        updateOfferGroup: build.mutation({
            query: (data) => ({
                url: `offergroups/${data.id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.offerGroups],
        }),

        // Payment Methods
        setOfferStripeConfig: build.mutation({
            query: (data) => ({
                url: `stripe/offer-payment-configurations/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.offerStripeConfig],
        }),
        updateOfferStripeConfig: build.mutation({
            query: ({ id, ...data }) => ({
                url: `stripe/offer-payment-configurations/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.offerStripeConfig],
        }),
        getOfferStripeConfig: build.query({
            query: ({ offer_id }) => ({
                url: `stripe/offer-payment-configurations/?offer=${offer_id}`,
            }),
            providesTags: [TAGS.offerStripeConfig],
        }),
    }),
})

const offerSlice = createSlice({
    name: 'offer',
    initialState: {
        editOfferContents: null,
        offerMsrpOverrideData: null,
    },
    reducers: {
        setEditOfferContents(state, action) {
            // eslint-disable-next-line no-param-reassign
            state.editOfferContents = action.payload
        },
        setOfferMsrpOverrideData(state, action) {
            // eslint-disable-next-line no-param-reassign
            state.offerMsrpOverrideData = action.payload
        },
    },
})

export const {
    useGetAllOffersQuery,
    useGetOfferQuery,
    useLazyGetOfferQuery,
    useDuplicateOfferMutation,
    useCreateOfferMutation,
    useUpdateOfferMutation,

    useGetAllOfferProductsQuery,
    useCreateOfferProductMutation,
    useUpdateOfferProductMutation,
    useDeleteOfferProductMutation,

    useGetAllOfferBundlesQuery,
    useCreateOfferBundleMutation,
    useDeleteOfferBundleMutation,

    useGetOfferAllMSRPOverridesQuery,
    useUpdateOfferMsrpOverrideMutation,
    useCreateOfferMsrpOverrideMutation,
    useDeleteOfferMsrpOverrideMutation,

    useCreateShippingRateMutation,
    useUpdateShippingRateMutation,

    useGetVariantShippingRatesQuery,
    useBulkManageVariantShippingRateMutation,
    useCreateVariantShippingRateMutation,
    useUpdateVariantShippingRateMutation,
    useDeleteVariantShippingRateMutation,

    useGetAllCurrencyOverridesQuery,
    useCreateCurrencyOverrideMutation,
    useUpdateCurrencyOverrideMutation,

    useGetAllShippingOverridesQuery,
    useUpdateShippingOverridesMutation,

    useListSupportedPaymentsQuery,
    useUpdateSupportedPaymentsMutation,

    useGetExistingGroupsQuery,
    useCreateOfferGroupMutation,
    useUpdateOfferGroupMutation,

    useSetOfferStripeConfigMutation,
    useUpdateOfferStripeConfigMutation,
    useGetOfferStripeConfigQuery,
} = extendApi
export const { setEditOffer, setEditOfferContents, setOfferMsrpOverrideData } = offerSlice.actions
export const { reducer } = offerSlice
