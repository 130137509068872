import { SvgIcon } from '@mui/material'

const CommissionsIcon = ({ sx }) => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={sx}
    >
        <path
            d="M2.5 7.1462V15.9999H7.36406L7.36355 14.537H3.96295V8.60915L2.5 7.1462Z"
            fill="black"
        />
        <path
            d="M12.6362 5.46289H16.037V11.3875L17.5 12.8505V3.99994H12.6368L12.6362 5.46289Z"
            fill="black"
        />
        <path
            d="M7.6366 4.00856L4.2684 4.00907L5.59073 2.68674L4.56804 1.66406L1.5 4.73211L4.56804 7.80015L5.59073 6.77747L4.2684 5.45514L7.63609 5.45514L7.6366 4.00856Z"
            fill="black"
        />
        <path
            d="M12.3634 14.5413L15.7316 14.5418L14.4093 13.2195L15.432 12.1968L18.5 15.2648L15.432 18.3329L14.4093 17.3102L15.7316 15.9879H12.3639L12.3634 14.5413Z"
            fill="black"
        />
        <path
            d="M10.7676 6.16173H9.23236V6.96776C8.35725 7.14662 7.69708 7.92117 7.69708 8.84848C7.69708 9.90629 8.5576 10.7676 9.61618 10.7676H10.3838C10.5949 10.7676 10.7676 10.9395 10.7676 11.1514C10.7676 11.3633 10.5949 11.5352 10.3838 11.5352H7.69708V13.0705H9.23236V13.8381H10.7676V13.0321C11.6428 12.854 12.3029 12.0787 12.3029 11.1514C12.3029 10.0936 11.4424 9.2323 10.3838 9.2323H9.61618C9.40508 9.2323 9.23236 9.06035 9.23236 8.84848C9.23236 8.63661 9.40508 8.46466 9.61618 8.46466H12.3029V6.92937H10.7676V6.16173Z"
            fill="black"
        />
    </SvgIcon>
)

export default CommissionsIcon
