import { createSlice } from '@reduxjs/toolkit'
import { api } from '../apiSlice'
import TAGS from '../cacheTags'
import { objectToQueryParams } from '@/utils/url-utils'
import { isTransactionItemCancelled } from '@/features/orders/utils'

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        // order
        getAllOrders: build.query({
            query: (params) => ({
                url: `orders/list/`,
                params: objectToQueryParams({
                    page: 1,
                    page_size: 10,
                    ...params,
                }),
            }),
            providesTags: [TAGS.orders],
        }),
        getOrder: build.query({
            query: ({ order_id }) => ({ url: `orders/${order_id}/` }),
            transformResponse: (order) => ({
                ...order,
                transactions:
                    order?.transactions.map((t) => ({
                        ...t,
                        items: t.items.filter((i) => !isTransactionItemCancelled(order, t, i)),
                    })) || [],
            }),
            providesTags: [TAGS.order],
        }),
        updateOrder: build.mutation({
            query: ({ order_id, ...data }) => ({
                url: `orders/${order_id}/`,
                method: 'PATCH',
                body: data,
            }),
        }),
        overrideReturnDeadline: build.mutation({
            query: ({ order_id, ...data }) => ({
                url: `orders/${order_id}/return-duration-override/`,
                method: 'PUT',
                body: data,
            }),
        }),

        getReturnReasonsList: build.query({
            query: (params) => ({
                url: `orders/brand-item-return-reasons/`,
                params: {
                    page: 1,
                    page_size: 999,
                    ...params,
                },
            }),
            providesTags: [TAGS.returnReasons],
        }),

        resendConfirmation: build.mutation({
            query: ({ order_id }) => ({
                url: `orders/${order_id}/resend-confirmation/`,
                method: 'POST',
            }),
        }),

        // delete
        deleteOrder: build.mutation({
            query: ({ id }) => ({
                url: `orders/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.orders],
        }),

        // cancel
        cancelOrder: build.mutation({
            query: ({ id, override, ...data }) => ({
                url: `orders/${id}/cancel/${override ? '?override=true' : ''}`,
                method: 'POST',
                body: data,
            }),
        }),
        cancelItem: build.mutation({
            query: ({ order_id, item_id, override, ...data }) => ({
                url: `orders/${order_id}/items/${item_id}/cancel/${
                    override ? '?override=true' : ''
                }`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.transactionItem, TAGS.transactionItems],
        }),

        // refund
        createRefund: build.mutation({
            query: ({ uid, ...data }) => ({
                url: `orders/${uid}/refund/`,
                method: 'POST',
                body: data,
            }),
        }),

        // return
        createReturn: build.mutation({
            query: ({ uid, ...data }) => ({
                url: `orders/${uid}/returns/`,
                method: 'POST',
                body: data,
            }),
        }),
        getReturns: build.query({
            query: ({ uid }) => ({
                url: `orders/${uid}/returns/`,
                method: 'GET',
            }),
            providesTags: [TAGS.orders, TAGS.orderReturns],
        }),

        // return item
        updateReturnedItems: build.mutation({
            query: ({ uid, id, ...data }) => ({
                url: `orders/${uid}/returns/${id}/receive-items/`,
                method: 'POST',
                body: data,
            }),
        }),
        deleteReturnItem: build.mutation({
            query: ({ uid, item_id }) => ({
                url: `orders/${uid}/return-items/${item_id}/`,
                method: 'DELETE',
            }),
        }),

        // transaction
        getAllTransactionItems: build.query({
            queryFn: async ({ order_id, ids }, _bqa, _n, fetchWithBQ) => {
                const promises = ids.map((id) =>
                    fetchWithBQ(`orders/${order_id}/transaction/items/${id}/`),
                )
                const { data } = await Promise.all(promises).then((results) => ({ data: results }))

                return {
                    data: data?.map((d) => d?.data) || [],
                }
            },
            providesTags: [TAGS.transactionItems],
        }),
        getTransactionItem: build.query({
            query: ({ order_id, item_id }) => ({
                url: `orders/${order_id}/transaction/items/${item_id}/`,
            }),
            providesTags: [TAGS.transactionItem],
        }),

        // cancelled item
        getCancelledItem: build.query({
            query: ({ order_id, item_id }) => ({
                url: `orders/${order_id}/cancelled-items/${item_id}/`,
            }),
        }),

        // cancelled item
        sendCustomerEmail: build.mutation({
            query: ({ uid, note }) => ({
                url: `orders/${uid}/anonymous-customer-email/`,
                method: 'POST',
                body: { note },
            }),
        }),
    }),
})

const ordersSlice = createSlice({
    name: 'order',
    initialState: {},
    reducers: {},
})

export const {
    useGetAllOrdersQuery,
    useGetOrderQuery,
    useUpdateOrderMutation,
    useOverrideReturnDeadlineMutation,

    useDeleteOrderMutation,

    useCancelOrderMutation,
    useCancelItemMutation,

    useCreateRefundMutation,

    useCreateReturnMutation,
    useGetReturnsQuery,
    useGetReturnReasonsListQuery,
    useResendConfirmationMutation,
    useUpdateReturnedItemsMutation,
    useDeleteReturnItemMutation,

    useGetAllTransactionItemsQuery,
    useGetTransactionItemQuery,
    useGetCancelledItemQuery,

    useSendCustomerEmailMutation,
} = extendedApi
export const ordersReducer = ordersSlice.reducer
