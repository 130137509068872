import { getCurrencySymbol, parseFloatFixed } from '@/utils/formatters'
/* eslint-disable */

// Calculate refund item shipping amount
export const cancelledShippingAmount = (order) =>
    order?.cancelled_items.reduce((acc, curr) => (acc += parseFloatFixed(curr.shipping_cost)), 0)

// Calculate the refund items amount refunded (without tax)
export const cancelledSubtotalAmount = (order) =>
    order?.cancelled_items?.reduce((acc, curr) => (acc += parseFloatFixed(curr.subtotal)), 0)

export const cancelledTaxAmount = (order) =>
    order?.cancelled_items?.reduce((acc, curr) => (acc += parseFloatFixed(curr.tax)), 0)

// Cancelled items from the order
export const getCancelledItems = (order, transaction, item) => {
    const filterByCatalogAndTransaction = (i) =>
        i.catalog_id === item.catalog_id && i.transaction === transaction.id

    return order?.cancelled_items?.filter(filterByCatalogAndTransaction) || []
}

// Calculate the cancelled amount of the transaction item
export const transactionItemCancelledAmount = (order, transaction, item) =>
    getCancelledItems(order, transaction, item)?.reduce((acc, curr) => {
        const itemPrice = +curr.item_price
        const quantity = +curr.quantity

        return (acc += parseFloatFixed(itemPrice * quantity))
    }, 0)

// Calculate the cancelled quantity of the transaction item
export const transactionItemCancelledQuantity = (order, transaction, item) =>
    getCancelledItems(order, transaction, item)?.reduce((acc, curr) => (acc += curr.quantity), 0)

// Check if the transaction item is cancelled
export const isTransactionItemCancelled = (order, transaction, item) =>
    item?.quantity === transactionItemCancelledQuantity(order, transaction, item)

// Calculate the remaining quantity after transaction item cancellation
export const transactionItemRemainingQuantity = (order, transaction, item) =>
    item?.quantity - transactionItemCancelledQuantity(order, transaction, item)

export const transactionAmountRefunded = (transaction) => {
    if (!transaction.refunds || transaction.refunds.length === 0) {
        return 0
    }

    return parseFloatFixed(
        transaction.refunds
            .map((refund) => parseFloat(refund.amount))
            .reduce((partialSum, amount) => partialSum + amount, 0),
    )
}

export const isFullyRefunded = (transaction) =>
    transactionAmountRefunded(transaction) === +transaction?.total

export const hasAmountForRefund = (refund) =>
    parseFloat(refund.amount) ||
    parseFloat(refund.shipping_amount) ||
    refund.refund_items.find((i) => !!parseFloat(i.amount))

export const transactionAmountAvailableForRefund = (transaction) =>
    parseFloatFixed(
        transaction.payments
            .map((payment) => parseFloat(payment.refundable_amount))
            .reduce((partialSum, amount) => partialSum + amount, 0),
    )

export const orderAmountAvailableForRefund = (order) =>
    +parseFloatFixed(
        order.transactions
            .map((transaction) => transactionAmountAvailableForRefund(transaction))
            .reduce((partialSum, amount) => partialSum + parseFloat(amount), 0),
    )

export const cancelledItemsTotal = (order) =>
    parseFloatFixed(
        order?.cancelled_items.reduce((sum, item) => {
            const tax = +item.tax
            const subtotal = +item.subtotal
            const shipping_cost = +item.shipping_cost
            const vatInclusive = !!order.vat_inclusive

            return (sum += parseFloatFixed(subtotal + shipping_cost + (vatInclusive ? 0 : tax)))
        }, 0),
    ) || 0

export const totalAmountSelectedForRefund = (refunds) =>
    refunds
        ?.flatMap((refund) => [...refund.refund_items.map((i) => i.amount), refund.shipping_amount])
        .reduce((total, amount) => (total += +amount), 0)

export const itemAmountAvailableForRefund = (order, item) =>
    +parseFloatFixed(+item.subtotal - itemAmountRefunded(order, item))

export const itemAmountRefunded = (order, item) =>
    +parseFloatFixed(
        order.transactions[item.transactionIndex].refunds
            .flatMap((r) =>
                item.id === 'shipping_amount'
                    ? [r.shipping_amount]
                    : r.refund_items.flatMap((i) => {
                          if (i.transaction_item !== item.id) {
                              return []
                          }

                          const amount = +i.amount || 0
                          const tax = +i.tax || 0

                          return amount - tax
                      }),
            )
            .reduce((acc, curr) => (acc += +curr), 0),
    )

export const isItemRefunded = (order, item) => {
    const transaction = order.transactions[item.transactionIndex]
    const availableForRefund = itemAmountAvailableForRefund(order, item)

    return (
        !!transaction.refunds.find((r) => +r.amount === +transaction.total) ||
        availableForRefund <= 0
    )
}

export const isItemPartiallyRefunded = (order, item) =>
    itemAmountRefunded(order, item) && itemAmountAvailableForRefund(order, item)

export const convertToCurrency = (amount, currency, currencyConversions, convertToCurrency) => {
    if (currency === convertToCurrency) return Number(amount).toFixed(2)
    const currencyConversion = currencyConversions.find((c) => c.currency === convertToCurrency)
    if (!currencyConversion) return null
    const { rate } = currencyConversion
    return (Number(amount) * Number(rate)).toFixed(2)
}

export const convertToCurrencyWithSymbolAndCode = ({
    amount,
    currency,
    currencyConversions,
    convertToCurrency: otherCurrency = 'USD',
}) => {
    const convertedAmount = convertToCurrency(amount, currency, currencyConversions, otherCurrency)
    return `${getCurrencySymbol(convertToCurrency)}${convertedAmount} ${otherCurrency}`
}

export const createConversionRateMessage = (currency, currencyConversions, convertToCurrency) => {
    if (currency === convertToCurrency) return `1 ${currency} = 1 ${convertToCurrency}`
    const currencyConversion = currencyConversions.find((c) => c.currency === convertToCurrency)
    if (!currencyConversion) return ''
    const { rate } = currencyConversion
    return `1 ${convertToCurrency} = ${(1 / Number(rate)).toFixed(5)} ${currency}`
}
