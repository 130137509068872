import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { usePathname } from 'next/navigation'
import { PATH_DASHBOARD } from '@/routes/paths'
import Performance from '@/components/icons/Performance'
import {
    BRANDS_ADD_BRAND,
    BRANDS_CURRENCY_OVERRIDE_VIEW,
    CATALOG_ADD_OFFER,
    CATALOG_CHANGE_OFFER,
    CATALOG_VIEW_BUNDLE,
    CATALOG_VIEW_OFFER,
    CATALOG_VIEW_PRODUCT,
    CMS_VIEW_PROVIDER,
    COMMISSION_ADD_PAYIN,
    COMMISSION_CHANGE_PAYIN,
    COMMISSION_VIEW_PAYIN,
    CUSTOMER_SERVICE_VIEW_PROVIDER,
    DISCOUNTS_VIEW_DISCOUNT_CODE,
    FULFILLMENT_VIEW_PROVIDER,
    MARKETING_EMAIL_VIEW_PROVIDER,
    MARKETING_PARTNER_MANAGE,
    ORDER_VIEW_ORDER,
    ORDER_VIEW_REPORT,
    PAYMENTS_VIEW_PROVIDER,
    TAX_VIEW_TAXREGION,
    USERS_VIEW_USER,
    WAREHOUSES_ADD_PROVIDER,
    WAREHOUSES_CHANGE_PROVIDER,
    WAREHOUSES_VIEW_PROVIDER,
} from '@/utils/enums/userPermissions'
import ShoppingBag from '@/components/icons/ShoppingBag'
import CliboardIcon from '@/components/icons/Clipboard'
import Lan from '@/components/icons/Lan'
import PriceTag from '@/components/icons/PriceTag'
import FilterIcon from '@/components/icons/FilterIcon'
import BoxIcon from '@/components/icons/BoxIcon'
import UserIcon from '@/components/icons/User'
import GearIcon from '@/components/icons/Gear'
import Crown from '@/components/icons/Crown'
import Building from '@/components/icons/Building'
import Payments from '@/components/icons/Payments'
import { useGetAllPaymentProvidersQuery } from '@/redux/slices/paymentsSlice'
import { getStripeAccountNumber } from '@/utils/payment-provider-utils'
import Support from '@/components/icons/Support'
import useZendesk from '@/hooks/useZendesk'
import useFeatureFlags from '@/hooks/useFeatureFlags'
import { INTERCOM, OFFER_PAYINS } from '@/utils/enums/featureFlags'
import Discount from '@/components/icons/Discount'
import CommissionsIcon from '@/components/icons/CommissionsIcon'

const useNavigation = (props) => {
    const pathname = usePathname()
    const { getChatAPIKey } = useZendesk()
    const { isFFEnabled } = useFeatureFlags()

    // Fetch Providers to determine if we render the Stripe Payouts in NavBar
    const currentBrand = useSelector((state) => state.brandSlice.currentBrand)
    const { data: providers } = useGetAllPaymentProvidersQuery(
        {},
        {
            skip: !props?.fetchProviders,
        },
    )

    const isAccountComplete = useMemo(
        () => !!getStripeAccountNumber(providers, currentBrand),
        [providers, currentBrand],
    )

    const openZendeskChat = (e) => {
        e.preventDefault()
        window?.zE?.('webWidget', 'show')
        window?.zE?.('webWidget', 'open')
    }

    const navConfig = useMemo(
        () => [
            // {
            //     title: 'Home',
            //     path: PATH_DASHBOARD.root,
            //     icon: <HomeIcon />,
            //     accessPermission: [],
            // },
            {
                title: 'Performance',
                path: PATH_DASHBOARD.performance.root,
                icon: <Performance />,
                accessPermission: ORDER_VIEW_REPORT,
            },
            {
                title: 'Payments',
                path: PATH_DASHBOARD.payments.root,
                icon: <Payments />,
                accessPermission: ORDER_VIEW_REPORT,
                hidden: !isAccountComplete,
            },
            {
                title: 'Orders',
                path: PATH_DASHBOARD.orders.root,
                icon: <ShoppingBag />,
                accessPermission: ORDER_VIEW_ORDER,
                subroutes: [
                    {
                        // This subroute covers the use case for orders/[id]
                        path: PATH_DASHBOARD.orders.root,
                        accessPermission: [],
                        isSubroute: true,
                        redirectOnBrandChange: PATH_DASHBOARD.orders.root,
                    },
                ],
            },
            {
                title: 'Products',
                path: PATH_DASHBOARD.products.root,
                icon: <CliboardIcon />,
                accessPermission: CATALOG_VIEW_PRODUCT,
                subroutes: [
                    {
                        // This subroute covers the use case for products/[id]
                        path: PATH_DASHBOARD.products.root,
                        accessPermission: [],
                        isSubroute: true,
                        redirectOnBrandChange: PATH_DASHBOARD.products.root,
                    },
                ],
            },
            {
                title: 'Bundles',
                path: PATH_DASHBOARD.bundles.root,
                icon: <Lan />,
                accessPermission: CATALOG_VIEW_BUNDLE,
                subroutes: [
                    {
                        // This subroute covers the use case for bundles/[id]
                        path: PATH_DASHBOARD.bundles.root,
                        accessPermission: [],
                        isSubroute: true,
                        redirectOnBrandChange: PATH_DASHBOARD.bundles.root,
                    },
                ],
            },
            {
                title: 'Offers',
                path: PATH_DASHBOARD.offers.root,
                subroutes: [
                    {
                        // This subroute covers the use case for offers/[id]
                        path: PATH_DASHBOARD.offers.root,
                        accessPermission: [
                            CATALOG_VIEW_OFFER,
                            CATALOG_ADD_OFFER,
                            CATALOG_CHANGE_OFFER,
                        ],
                        isSubroute: true,
                        redirectOnBrandChange: PATH_DASHBOARD.offers.root,
                    },
                ],
                icon: <PriceTag />,
                accessPermission: CATALOG_VIEW_OFFER,
            },
            {
                title: 'Discounts',
                path: PATH_DASHBOARD.discounts.root,
                icon: <Discount />,
                accessPermission: DISCOUNTS_VIEW_DISCOUNT_CODE,
            },
            {
                title: 'Funnels',
                path: process.env.GU_FUNNELS_URL,
                target: '_blank',
                icon: <FilterIcon />,
                accessPermission: CMS_VIEW_PROVIDER,
                envs: ['partner.giddyup.io'],
            },
            {
                title: 'Shipping',
                path: PATH_DASHBOARD.shipping.root,
                subroutes: [
                    {
                        path: PATH_DASHBOARD.shipping.warehouses.root,
                        accessPermission: [
                            WAREHOUSES_ADD_PROVIDER,
                            WAREHOUSES_CHANGE_PROVIDER,
                            WAREHOUSES_VIEW_PROVIDER,
                        ],
                        isSubroute: true,
                        redirectOnBrandChange: PATH_DASHBOARD.shipping.root,
                    },
                ],
                icon: <BoxIcon />,
                accessPermission: [WAREHOUSES_VIEW_PROVIDER],
            },
            {
                title: 'Users',
                path: PATH_DASHBOARD.users.root,
                icon: <UserIcon />,
                accessPermission: USERS_VIEW_USER,
            },
            {
                title: 'Brands',
                path: PATH_DASHBOARD.brands.create,
                // This config is not visible in the navbar
                hidden: true,
                icon: <Building />,
                accessPermission: BRANDS_ADD_BRAND,
            },
            {
                title: 'Commissions',
                path: PATH_DASHBOARD.commissions.root,
                hidden: !isFFEnabled(OFFER_PAYINS),
                subroutes: [
                    {
                        // This subroute covers the use case for commissions/[id]
                        path: PATH_DASHBOARD.commissions.root,
                        accessPermission: [
                            COMMISSION_ADD_PAYIN,
                            COMMISSION_CHANGE_PAYIN,
                            COMMISSION_VIEW_PAYIN,
                        ],
                        isSubroute: true,
                        redirectOnBrandChange: PATH_DASHBOARD.commissions.root,
                    },
                ],
                icon: <CommissionsIcon />,
                accessPermission: COMMISSION_VIEW_PAYIN,
            },
            {
                title: 'Settings',
                path: PATH_DASHBOARD.settings.brand.root,
                subroutes: [
                    {
                        path: PATH_DASHBOARD.settings.brand.root,
                        accessPermission: [],
                        isSubroute: true,
                    },
                    {
                        path: PATH_DASHBOARD.settings.currencies.root,
                        accessPermission: [BRANDS_CURRENCY_OVERRIDE_VIEW],
                        isSubroute: true,
                    },
                    {
                        path: PATH_DASHBOARD.settings.taxes.root,
                        accessPermission: [TAX_VIEW_TAXREGION],
                        isSubroute: true,
                    },
                    {
                        path: PATH_DASHBOARD.settings.payment.root,
                        accessPermission: [PAYMENTS_VIEW_PROVIDER],
                        isSubroute: true,
                    },
                    {
                        path: PATH_DASHBOARD.settings.integrations.root,
                        accessPermission: [
                            FULFILLMENT_VIEW_PROVIDER,
                            MARKETING_EMAIL_VIEW_PROVIDER,
                            CUSTOMER_SERVICE_VIEW_PROVIDER,
                        ],
                        isSubroute: true,
                    },

                    // These sub routes covers the use case for settings/[type]/[id]
                    {
                        path: PATH_DASHBOARD.settings.email.root,
                        isSubroute: true,
                        accessPermission: [MARKETING_EMAIL_VIEW_PROVIDER],
                        redirectOnBrandChange: PATH_DASHBOARD.settings.integrations.root,
                    },
                    {
                        path: PATH_DASHBOARD.settings.fulfillment.root,
                        isSubroute: true,
                        accessPermission: [FULFILLMENT_VIEW_PROVIDER],
                        redirectOnBrandChange: PATH_DASHBOARD.settings.integrations.root,
                    },
                    {
                        path: PATH_DASHBOARD.settings.supportProviders.root,
                        isSubroute: true,
                        accessPermission: [CUSTOMER_SERVICE_VIEW_PROVIDER],
                        redirectOnBrandChange: PATH_DASHBOARD.settings.integrations.root,
                    },
                ],
                icon: <GearIcon />,
                accessPermission: [],
            },
            {
                title: 'Support',
                path: pathname,
                onClick: isFFEnabled(INTERCOM) ? () => {} : openZendeskChat,
                hidden: !isFFEnabled(INTERCOM) && !getChatAPIKey(),
                id: isFFEnabled(INTERCOM) ? 'intercom_custom_launcher' : 'zendesk-custom-launcher',
                icon: <Support />,
                accessPermission: [],
            },
            {
                title: 'MP Tools',
                path: PATH_DASHBOARD.mptools.domains.root,
                icon: <Crown />,
                isMPOption: true,
                subroutes: [
                    {
                        path: PATH_DASHBOARD.mptools.domains.root,
                        accessPermission: [],
                        isSubroute: true,
                    },
                    {
                        path: PATH_DASHBOARD.mptools.externalSubdomains.root,
                        accessPermission: [],
                        isSubroute: true,
                    },
                ],
                accessPermission: MARKETING_PARTNER_MANAGE,
            },
        ],
        [pathname, isAccountComplete],
    )

    return {
        navConfig,
    }
}

export default useNavigation
