export const UPGRADES = 'UPGRADES'
export const OFFER_PAYINS = 'OFFER_PAYINS'
export const TARGETED_UPSELLS = 'TARGETED_UPSELLS'
export const POST_TRANSACTION_UPGRADES = 'POST_TRANSACTION_UPGRADES'
export const SHOW_STRIPE_MODIFY_BUTTON = 'SHOW_STRIPE_MODIFY_BUTTON'

export const ORDER_LIST_DETAIL_UPDATES = 'ORDER_LIST_DETAIL_EXPORT_UPDATES'

export const INTERCOM = 'INTERCOM'

export const SHOPPER_RETURNS = 'SHOPPER_RETURNS'

export const AMAZON_MCF = 'AMAZON_MCF'

export const DYNAMIC_PAYMENTS = 'DYNAMIC_PAYMENTS'
